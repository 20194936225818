<template>

  <div class="bg-container page">
    <img class="bg-img" src="img/login/login-bg.png">
    <div class="bg-wrapper">
      <div class="register">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:''">
            </div>
          </div>
          <div class="title">Đăng Ký</div>
          <div class="loginForm">
            <van-field v-model="username"  clearable input-align="center" class="input"  placeholder="Tên đăng nhập" />
            <van-field
                v-model="password"
                :type="passwordType"
                input-align="center"
                class="input"
                placeholder="Mật khẩu">
              <template slot="right-icon">
                <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
              </template>
            </van-field>
            <van-field v-model="code"  clearable input-align="center" class="input"  placeholder="Mã giới thiệu" />
            <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text">Tôi đã biết và đồng ý với các điều khoản của "Trang Web"</span>
            </div>
            <van-button class="login-btn"   type="primary" size="normal" @click="doRegister()">Đăng Ký</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
 
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      password: this.inputValue,
      passwordType: 'password',
    };
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast.fail("Vui lòng nhập tên người dùng！");
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast.fail("Vui lòng nhập mật khẩu！");
        return false;
      }
      if(this.code === "" || this.code === null || this.code === undefined){
        this.$toast.fail("Vui lòng nhập mã giới thiệu！");
        return false;
      }
      if(!this.checked){
        this.$toast.fail("Vui lòng đánh dấu vào thỏa thuận mở tài khoản bên dưới！");
        return false;
      }
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
          code:this.code
        },
        url: 'member_register'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data)
            this.$router.push("Mine")
          }else {
            this.$toast.fail(res.msg);
          }
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.bg-container .bg-wrapper {
  background: linear-gradient(hsl(0deg 0% 100% / 37%), #948cb7);
}
.register{
  height: 100vh;
  overflow:hidden;
}
.bg-container .bg-wrapper .register .nav-bar{
  background: 0 0
}
.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container{
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.register .wrapper .title{
  line-height: 70px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 1px;
}
.register .wrapper .loginForm{
  padding: 15px 30px;
}
.register .wrapper .loginForm .input{
  padding: 10px 10px;
  margin-top: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
  color: #4e4e4e;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 17px;
}
::v-deep .van-icon {
  font-size: 14px;
}
.register .wrapper .loginForm .reset-text{
  margin: 15px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}
.register .wrapper .loginForm .register-text{
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span{
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}
.register .wrapper .loginForm .login-btn{
  margin-top: 5px;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    color: #fff;
    background-color: #8774d3;
    font-size: 16px;
    font-weight: bolder;
    border: none; 
}
.register .wrapper .loginForm .agreement{
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text{
  margin-left: 10px;
  font-size: 11px;
  color: #fff;
  font-weight: 500;
  line-height: 20px;
}
::v-deep .agreement 
 {
  font-size: 16px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 18px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: #8774d3;
  background-color: #8774d3;
}
</style>
